// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.PickFeatureMenu {
    border: 1px solid var(--border-color);
    background-color: var(--list-bg-color);
    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);
}

div.PickFeatureMenu > div {
    padding: 0.25em 0.5em;
}

div.PickFeatureMenu > div:hover:not(.pick-feature-menu-querying) {
    background-color: var(--list-item-bg-color-hover);
}

div.pick-feature-menu-querying {
    display: flex;
    align-items: center;
}

div.pick-feature-menu-querying > div.spinner {
    width: 1em;
    height: 1em;
    flex: 0 0 auto;
    margin-right: 0.5em;
}`, "",{"version":3,"sources":["webpack://./qwc2/components/style/PickFeature.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,sCAAsC;IACtC,gDAAgD;AACpD;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,cAAc;IACd,mBAAmB;AACvB","sourcesContent":["div.PickFeatureMenu {\n    border: 1px solid var(--border-color);\n    background-color: var(--list-bg-color);\n    box-shadow: 0px 2px 4px rgba(136, 136, 136, 0.5);\n}\n\ndiv.PickFeatureMenu > div {\n    padding: 0.25em 0.5em;\n}\n\ndiv.PickFeatureMenu > div:hover:not(.pick-feature-menu-querying) {\n    background-color: var(--list-item-bg-color-hover);\n}\n\ndiv.pick-feature-menu-querying {\n    display: flex;\n    align-items: center;\n}\n\ndiv.pick-feature-menu-querying > div.spinner {\n    width: 1em;\n    height: 1em;\n    flex: 0 0 auto;\n    margin-right: 0.5em;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
