// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.suggestion-input {
    display: inline-block;
}

span.suggestion-input > input {
    width: 100%;
    height: 100%;
}
`, "",{"version":3,"sources":["webpack://./qwc2/components/widgets/style/SuggestionInput.css"],"names":[],"mappings":"AAAA;IACI,qBAAqB;AACzB;;AAEA;IACI,WAAW;IACX,YAAY;AAChB","sourcesContent":["span.suggestion-input {\n    display: inline-block;\n}\n\nspan.suggestion-input > input {\n    width: 100%;\n    height: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
